@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'SF-Pro-Display';
    src: url('./assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'SF-Pro-Display', sans-serif;
  }
  @layer base {
    body {
      @apply bg-gray-100 text-gray-900;
    }
  }
  
  @layer components {
    .btn {
      @apply px-4 py-2 rounded font-semibold text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-opacity-75;
    }
    .btn-primary {
      @apply bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-400;
    }
  }

  /* Custom Scrollbar Styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}